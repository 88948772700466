<template>
  <div class="flex items-center gap-2 font-medium">
    <FontAwesomeIcon v-if="icon" :icon="['far', icon]" />
    <span class="text-lg">
      <slot />
    </span>
    <div class="flex flex-row flex-wrap gap-2 ml-auto mr-0">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    }
  }
};
</script>
