<template>
  <div>

    <PWidgetHeader icon="life-ring">
      <PTranslation k="PSupportWidget.Support" />
    </PWidgetHeader>

    <PLoader
      class="mt-2"
      v-if="isLoading"
    />

    <template v-else>

        <template v-if="supportTickets.length > 0">

          <p class="mt-2 text-sm">
            {{ $t("PSupportWidget.ThereAreXWaiting", { count: supportTickets.length }) }}
          </p>

          <RouterLink
            class="flex items-center justify-between mt-4 group space-x-4"
            v-for="(ticket, index) in supportTickets"
            :key="index"
            :to="{ name: 'support.Tickets', params: { id: ticket.id } }"
          >
            <div>
              <h2 class="text-sm font-semibold text-green-600 underline group-hover:text-black">
                #{{ ticket.id }}: {{ ticket.title }}
              </h2>
              <div class="text-xs">{{ ticket.created | date }} / {{ ticket.createdBy.name }}<br/>
              {{ ticket.customerName }}
              </div>
              <div
                class="text-xs"
                :class="{
                  'text-red-500 font-semibold': ticket.priority === 'High'
                }"
                v-if="location.isSmartRetur"
              >
                <span v-html="$tk('Common.General.Priority')"></span>: {{
                  ticket.priority === "High" ? $tk("Common.SupportPriority.High", true) :
                  ticket.priority === "Low" ? $tk("Common.SupportPriority.Low", true) : ticket.priority
                }}
              </div>
            </div>
            <div class="text-xs">
              <PStatusLabel :status="ticket.status" />
            </div>
          </RouterLink>

        </template>

        <template v-else>
          <p
            class="mt-2 text-sm"
            v-html="$tk('PSupportWidget.ThereAreNoAwaitingSupportCases')"
          ></p>
        </template>

    </template>

  </div>
</template>

<script>

import http from "@/utilities/http"
import PWidgetHeader from "./PWidgetHeader"
import PStatusLabel from "@/areas/support/components/PStatusLabel"
import { mapGetters } from "vuex"

export default {

  components: {
    PWidgetHeader,
    PStatusLabel
  },

  data () {
    return {
      supportTickets: [],
      isLoading: false
    }
  },

  computed: {
    ...mapGetters([
      "location"
    ])
  },

  methods: {

    handleError (error) {
      this.$store.dispatch("notify", {
        type: "negative",
        text: error.reason
      })
    },

    async getSupportTickets () {
      this.isLoading = true
      try {

        let params = {}
        if (this.location.isSmartRetur) {
          params.status = 0
        } else {
          params.status = 2
          params.locationId = this.location.id
        }

        this.supportTickets = await http.get("SupportTickets", { params })

      } catch (error) {
        this.handleError(error)
      }
      this.isLoading = false
    }
  },

  async created () {
    await this.getSupportTickets()
  }

  // watch: {
  //   location: {
  //     handler: async function () {
  //       await this.getSupportTickets()
  //     },
  //     immediate: true
  //   }
  // }

}
</script>