var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex items-center justify-between mb-4"},[_c('PWidgetHeader',{staticClass:"mb-2",attrs:{"icon":"shopping-cart"}},[_vm._v(" "+_vm._s(_vm.term ? _vm.$t("POrdersWidget.ResultsForSearchOnTermInAllOrders", { term: _vm.term }) : _vm.$tk('POrdersWidget.ActiveOrders', true))+" ")]),_c('div',[_c('PInput',{staticClass:"w-48",attrs:{"iconInsideLeft":"search","placeholder":_vm.$tk('POrdersWidget.SearchText', true),"clearable":true,"wait":500},model:{value:(_vm.term),callback:function ($$v) {_vm.term=$$v},expression:"term"}})],1)],1),(_vm.isLoading)?_c('PLoader'):_c('div',{staticClass:"overflow-y-auto max-h-64"},[_c('table',{staticClass:"w-full text-sm"},_vm._l((_vm.orders),function(order,i){return _c('tr',{key:i,staticClass:"bg-gray-100 border-b-4 border-white cursor-pointer group",class:{
          'hover:bg-orange-200': order.transactionStatus !== 'NotAgreed',
          'hover:bg-red-300 bg-red-200 text-red-700': order.transactionStatus === 'NotAgreed'
        },on:{"click":function($event){return _vm.onClick(order)}}},[_c('td',{staticClass:"font-semibold underline group-hover:text-black",class:{
          'text-green-600': order.transactionStatus !== 'NotAgreed',
          'text-red-700': order.transactionStatus === 'NotAgreed'
        }},[_vm._v(" "+_vm._s(order.id)+" ")]),_c('td',[_vm._v(" "+_vm._s(order.transporterName)+" "),(order.shipmentRef)?_c('div',{staticClass:"text-xs"},[_vm._v(_vm._s(order.shipmentRef))]):_vm._e(),(order.transportCarRegNumber)?_c('div',{staticClass:"text-xs"},[_vm._v(_vm._s(order.transportCarRegNumber))]):_vm._e()]),_c('td',[_c('div',{staticClass:"flex flex-col lg:flex-row lg:space-x-2"},_vm._l((order.transactions),function(transaction,j){return _c('div',{key:j,staticClass:"flex items-center"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: transaction.qualityName }),expression:"{ content: transaction.qualityName }"}],attrs:{"xclass":"bg-white rounded-full shadow h-8 w-8 flex items-center justify-center"}},[_c('img',{attrs:{"alt":"","src":`${_vm.assetsBaseUrl}/media.ashx?${transaction.productImage}&template=product-icon`}})]),_c('div',{staticClass:"ml-1"},[_vm._v(_vm._s(transaction.quantity))])])}),0)]),_c('td',{domProps:{"innerHTML":_vm._s(_vm.typeText(order.transactionTypeId))}}),_c('td',{staticClass:"whitespace-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("date")(order.dateSend))+" ")]),_c('td',{staticClass:"text-center whitespace-no-wrap"},[_c('PDisplayStatus',{attrs:{"item":order}})],1)])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }